import React, { useState } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Azul from '../../../assets/airCampaign/azul.png';
import Latam from '../../../assets/airCampaign/latam.png';
import Smiles from '../../../assets/airCampaign/smiles.png';
import Iberia from '../../../assets/airCampaign/iberiaplus.png';
import Copa from '../../../assets/airCampaign/copa-logo.png';
import { StyledSwitch } from '../../RuleItem/styles';
import CancelCampaign from '../CancelCampaign';
import FutureCampaign from '../FutureCampaign';
import HistoryCampaign from '../HistoryCampaign';
import * as S from './styles';

export default function AirCampaignTableBody({
  item,
  userPermissions,
  isModifiedAirCampaign,
  setIsModifiedAirCampaign,
  populateToastMessages,
}) {
  const [isSwitchOn, setIsSwitchOn] = useState(item.status === 'ACTIVE');
  const [isModifiedFuture, setIsModifiedFuture] = useState(false);
  const [isRefreshHistory, setIsRefreshHistory] = useState(false);
  const [isOpenModalCancelCampaign, setIsOpenModalCancelCampaign] =
    useState(false);

  const [classActive, setClassActive] = useState(false);

  const [viewTable, setViewTable] = useState(false);
  const [currentSeller, setCurrentSeller] = useState('');
  const handleShowMdlCancelCampaign = () => setIsOpenModalCancelCampaign(true);
  const handleCloseMdlCancelCampaign = () =>
    setIsOpenModalCancelCampaign(false);

  const handleChangeSiwtch = () => {
    if (isSwitchOn) {
      handleShowMdlCancelCampaign();
    }
  };

  const handleViewTable = () => {
    setViewTable(!viewTable);
    setClassActive(!classActive);
  };

  return (
    <>
      <S.StyledTr isInactive={item.status === 'ACTIVE'}>
        {item.seller === 'dsml' && (
          <td className="partner">
            <span
              aria-hidden="true"
              className={`esfera-icon BE040 ${classActive ? 'active' : ''}`}
              onClick={() => {
                setCurrentSeller(item.seller);
                handleViewTable();
              }}
            />
            <img src={Smiles} alt="Smiles" />
          </td>
        )}
        {item.seller === 'dazl' && (
          <td className="partner">
            <span
              aria-hidden="true"
              className={`esfera-icon BE040 ${classActive ? 'active' : ''}`}
              onClick={() => {
                setCurrentSeller(item.seller);
                handleViewTable();
              }}
            />
            <img src={Azul} alt="Azul" />
          </td>
        )}
        {item.seller === 'dmtl' && (
          <td className="partner">
            <span
              aria-hidden="true"
              className={`esfera-icon BE040 ${classActive ? 'active' : ''}`}
              onClick={() => {
                setCurrentSeller(item.seller);
                handleViewTable();
              }}
            />
            <img src={Latam} alt="Latam" />
          </td>
        )}
        {item.seller === 'dibp' && (
          <td className="partner">
            <span
              aria-hidden="true"
              className={`esfera-icon BE040 ${classActive ? 'active' : ''}`}
              onClick={() => {
                setCurrentSeller(item.seller);
                handleViewTable();
              }}
            />
            <img src={Iberia} alt="Iberia" />
          </td>
        )}
        {item.seller === 'dcop' && (
          <td className="partner">
            <span
              aria-hidden="true"
              className={`esfera-icon BE040 ${classActive ? 'active' : ''}`}
              onClick={() => {
                setCurrentSeller(item.seller);
                handleViewTable();
              }}
            />
            <img src={Copa} alt="Copa" />
          </td>
        )}

        <td className="emphasis">
          {item.campaignName ? item.campaignName : '---'}
        </td>
        <td>{item.adminUser ? item.adminUser : '---'}</td>
        <td>
          {item.startDate
            ? moment(item.startDate).format('DD/MM/YYYY HH:mm')
            : '---'}
        </td>
        <td>
          {item.endDate
            ? moment(item.endDate).format('DD/MM/YYYY HH:mm')
            : '---'}
        </td>
        <td>
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.MILES_CAMPAIGN.UPDATE',
            userPermissions.userGroups,
          ) ? (
            <div className="container-actions" data-tip data-for={item.id}>
              <StyledSwitch
                type="switch"
                id={`switch ${item.id}`}
                onChange={handleChangeSiwtch}
                checked={isSwitchOn}
              />
              {!isSwitchOn && (
                <ReactTooltip
                  id={item.campaignName}
                  place="bottom"
                  effect="solid"
                  className="tooltip-campaign"
                >
                  Você não pode ativar esta campanha. Para criar uma nova,
                  clique no botão &#34;Cadastrar campanha&#34;.
                </ReactTooltip>
              )}
            </div>
          ) : null}
        </td>

        <CancelCampaign
          campaignId={item.id}
          method="disable"
          isOpenModal={isOpenModalCancelCampaign}
          setIsModifiedFuture={setIsModifiedFuture}
          handleCloseModal={handleCloseMdlCancelCampaign}
          userPermissions={userPermissions}
          setIsSwitchOn={setIsSwitchOn}
          populateToastMessages={populateToastMessages}
          setIsRefreshHistory={setIsRefreshHistory}
        />
      </S.StyledTr>

      {viewTable && (
        <>
          <FutureCampaign
            sellerId={currentSeller}
            userPermissions={userPermissions}
            isModifiedAirCampaign={isModifiedAirCampaign}
            setIsModifiedAirCampaign={setIsModifiedAirCampaign}
            setIsModifiedFuture={setIsModifiedFuture}
            isModifiedFuture={isModifiedFuture}
            setIsRefreshHistory={setIsRefreshHistory}
            populateToastMessages={populateToastMessages}
          />
          <HistoryCampaign
            sellerId={currentSeller}
            userPermissions={userPermissions}
            setIsRefreshHistory={setIsRefreshHistory}
            isRefreshHistory={isRefreshHistory}
            populateToastMessages={populateToastMessages}
          />
        </>
      )}
    </>
  );
}
